import { Theme } from '@mui/material/styles'
import { indexMobileBreakpoint } from './types'
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'

export const indexBodyText: (theme: Theme) => SystemStyleObject<Theme> = (
  theme,
) => ({
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  letterSpacing: '0.5px',
  color: 'secondary.950',
  [theme.breakpoints.down(indexMobileBreakpoint)]: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
})
