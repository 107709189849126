import { useEffect, useRef, useState } from 'react'
import { Button, SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface GradientButtonProps {
  children: React.ReactNode
  onClick?: () => void
  href?: string
  borderWidth?: number
  sx?: SxProps<Theme>
  ariaLabel: string
  small?: boolean
}

const GradientButton: React.FC<GradientButtonProps> = ({
  children,
  onClick,
  href,
  sx = [],
  borderWidth = 1,
  ariaLabel,
  small = false,
}) => {
  const uniqueId = 'gradientForButton'
  const containerRef = useRef<HTMLButtonElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!containerRef.current) return

    const resizeObserver = new ResizeObserver((entries) => {
      const { inlineSize, blockSize } = entries[0].borderBoxSize[0]
      setDimensions({
        width: inlineSize + borderWidth * 2,
        height: blockSize + borderWidth * 2,
      })
    })

    resizeObserver.observe(containerRef.current)

    // Initial measure
    const { width, height } = containerRef.current.getBoundingClientRect()
    setDimensions({
      width: width + borderWidth * 2,
      height: height + borderWidth * 2,
    })

    return () => resizeObserver.disconnect()
  }, [borderWidth])

  return (
    <Button
      color='inherit'
      href={href}
      aria-label={ariaLabel}
      onClick={onClick}
      component='button'
      ref={containerRef}
      sx={[
        {
          px: small ? 1.5 : 2,
          py: small ? 0.75 : 1.5,
          position: 'relative',
          display: 'inline-flex',
          boxSizing: 'border-box',
          borderRadius: 100,
          '&:hover': {
            bgcolor: 'secondary.100',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {/* SVG Border */}
      <svg
        width={dimensions.width || 0}
        height={dimensions.height || 0}
        style={{
          position: 'absolute',
          top: -borderWidth,
          left: -borderWidth,
          pointerEvents: 'none',
        }}
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <radialGradient
            id={uniqueId}
            cx='0%'
            cy='100.01%'
            r='138.99%'
            fx='0%'
            fy='100.01%'
          >
            <stop offset='0%' stopColor='#240448' />
            <stop offset='28.81%' stopColor='#1200B5' />
            <stop offset='64.04%' stopColor='#9C0DC8' />
          </radialGradient>
        </defs>
        <rect
          x={borderWidth / 2}
          y={borderWidth / 2}
          width={Math.max(dimensions.width - borderWidth, 0)}
          height={Math.max(dimensions.height - borderWidth, 0)}
          // This is the key: make the radius half of the height to create a capsule
          rx={Math.max((dimensions.height - borderWidth) / 2, 0)}
          ry={Math.max((dimensions.height - borderWidth) / 2, 0)}
          fill='transparent'
          stroke={`url(#${uniqueId})`}
          strokeWidth={borderWidth}
        />
      </svg>

      {/* Button Content */}
      <Typography
        variant='button'
        sx={[
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: small ? '1rem' : '0.875rem',
            color: 'inherit',
          },
          ...(Array.isArray(sx) &&
          sx.some((style) => style?.background || style?.bgcolor)
            ? []
            : [
                {
                  background:
                    'linear-gradient(89.01deg, #290057 21.37%, #1200B5 57.59%, #9C0DC8 96.95%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                },
              ]),
        ]}
      >
        {children}
      </Typography>
    </Button>
  )
}

export default GradientButton
