import React from 'react'
import { Box } from '@mui/material'

const LogoGradient = (props) => (
  <Box
    component='svg'
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <mask
      id='mask0_6591_8584'
      style={{
        maskType: 'alpha',
      }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='-1'
      width='21'
      height='22'
    >
      <path
        d='M1 4C1 2.11438 1 1.17157 1.5852 0.585786C2.1704 0 3.11227 0 4.99601 0H5.12088C7.00462 0 7.94648 0 8.53168 0.585786C9.11689 1.17157 9.11689 2.11438 9.11689 4V16C9.11689 17.8856 9.11689 18.8284 8.53168 19.4142C7.94648 20 7.00461 20 5.12088 20H4.996C3.11227 20 2.1704 20 1.5852 19.4142C1 18.8284 1 17.8856 1 16V4Z'
        fill='#4D4D4D'
        stroke='#4D4D4D'
        strokeWidth='0.465658'
      />
      <path
        d='M13.8837 20C13.1686 20 12.8111 20 12.5208 19.9094C11.8939 19.7139 11.403 19.2225 11.2077 18.595C11.1172 18.3044 11.1172 17.9465 11.1172 17.2308L11.1172 13.7692C11.1172 13.0535 11.1172 12.6956 11.2077 12.405C11.403 11.7775 11.8939 11.2861 12.5208 11.0906C12.8111 11 13.1686 11 13.8837 11L15.6127 11C18.0955 11 20.1082 13.0147 20.1082 15.5C20.1082 17.9853 18.0955 20 15.6127 20L13.8837 20Z'
        fill='#4D4D4D'
        stroke='#4D4D4D'
        strokeWidth='0.465658'
      />
      <path
        d='M20.1043 4.5C20.1043 6.98528 18.0916 9 15.6088 9C13.126 9 11.1133 6.98528 11.1133 4.5C11.1133 2.01472 13.126 0 15.6088 0C18.0916 0 20.1043 2.01472 20.1043 4.5Z'
        fill='#4D4D4D'
        stroke='#4D4D4D'
        strokeWidth='0.465658'
      />
    </mask>
    <g mask='url(#mask0_6591_8584)'>
      <rect width='21' height='20' fill='url(#paint0_radial_6591_8584)' />
    </g>
    <defs>
      <radialGradient
        id='paint0_radial_6591_8584'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(4.13293 20) rotate(-45.339) scale(28.1184 27.1797)'
      >
        <stop stopColor='#240448' />
        <stop offset='0.288127' stopColor='#1200B5' />
        <stop offset='0.640404' stopColor='#9C0DC8' />
      </radialGradient>
    </defs>
  </Box>
)

export default LogoGradient
