import Head from 'next/head'
import Column from '../src/components/Column'
import React, { useEffect } from 'react'
import { OrganisationDto } from '../src/types/organisation'
import mixpanel from 'shared-components/utils/mixpanel'
import IndexTopSection from '../src/components/Index/IndexTopSection'
import { Box } from '@mui/material'
import dynamic from 'next/dynamic'
import HomeHeader from '../src/components/Index/HomeHeader'
import CookieModal from '../src/components/Cookies/CookieModal.js'

const IndexStickyStepper = dynamic(
  () => import('../src/components/Index/IndexStickyStepper'),
  {
    ssr: false,
  },
)

const IndexOrganisersSection = dynamic(
  () => import('../src/components/Index/IndexOrganisersSection'),
  {
    ssr: false,
  },
)

const IndexFAQ = dynamic(() => import('../src/components/Index/IndexFAQ'), {
  ssr: false,
})

const IndexDemoSection = dynamic(
  () => import('../src/components/Index/IndexDemoSection'),
  {
    ssr: false,
  },
)

const IndexReferences = dynamic(
  () => import('../src/components/Index/IndexReferences'),
  {
    ssr: false,
  },
)

const GeneralFooter = dynamic(
  () => import('../src/components/event/GeneralFooter'),
  {
    ssr: false,
  },
)

export const getStaticProps = async (context) => {
  try {
    const response = await fetch(
      'https://api.bash.social/organisations/featured-v2?size=20&imageSizesOrgAvatar=lg',
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Accept-Language': locale,
        },
      },
    )
    const results = await response.json()

    return {
      props: {
        pages: results.slice(0, 20),
      },
      // Cache the page for 24 hours (86400 seconds)
      revalidate: 86400,
    }
  } catch (error) {
    console.error('Error fetching data:', error)

    // Return empty data in case of error to prevent build failure
    return {
      props: {
        pages: [],
      },
      revalidate: 3600, // Retry after 1 hour if there was an error
    }
  }
}

interface Props {
  pages: OrganisationDto[]
}

const Index = ({ pages }: Props) => {
  useEffect(() => {
    mixpanel.track('Visit homepage')
  }, [])

  return (
    <Column
      sx={{
        alignItems: 'center',
        background:
          'radial-gradient(106.74% 40.21% at -6.74% 36.73%, rgba(36, 4, 72, 0.03) 0%, rgba(18, 0, 181, 0.03) 28.81%, rgba(156, 13, 200, 0.03) 64.04%, rgba(255, 94, 0, 0.03) 100%) ,linear-gradient(180deg, #FFFFFF 3.05%, rgba(255, 255, 255, 0) 7.58%)',
        color: 'secondary.950',
      }}
    >
      <Head>
        {/* Resource Hints */}
        <link rel='dns-prefetch' href={process.env.NEXT_PUBLIC_API_HOST} />

        <link
          rel='preload'
          // href='/images/home/landing_hero.mp4'
          href='/images/home/landing_hero.mp4'
          as='video'
          type='video/mp4'
        />
      </Head>

      <Column
        sx={{
          width: 1,
          bgcolor: '#fff',
          alignItems: 'center',
        }}
      >
        <HomeHeader />
      </Column>

      <Column
        sx={{
          width: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IndexTopSection
          sx={{
            width: 1,
          }}
        />

        <IndexStickyStepper />

        <IndexOrganisersSection pages={pages} />

        <Box
          sx={{
            overflowX: 'hidden',
            width: 1,
          }}
        >
          <IndexFAQ />
        </Box>

        <Box
          sx={{
            overflowX: 'hidden',
            width: 1,
          }}
        >
          <IndexDemoSection />
        </Box>

        <IndexReferences
          sx={{
            mb: 4,
            width: 1,
          }}
        />

        <Column
          sx={{
            width: 1,
            maxWidth: 1120,
            px: 3,
            alignItems: 'center',
            color: 'secondary.950',
          }}
        >
          <GeneralFooter
            maxWidth={9999}
            hideBorder
            // @ts-expect-error color is correct
            color='secondary.950'
            isIndex={true}
          />
        </Column>
      </Column>
      <CookieModal
        isIndex={true}
        onAccept={() => {
          mixpanel.track('Visit homepage')
        }}
      />
    </Column>
  )
}

Index.trimApp = true

export default Index
