import { Add } from '@mui/icons-material'
import GradientButton from '../../shared-components/buttons/GradientButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface Props {
  sx?: SxProps<Theme>
}

const CreateFirstEventButton = ({ sx = [] }: Props) => {
  const { t } = useTranslation('common')
  return (
    <GradientButton
      sx={[
        {
          width: 'fit-content',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      href='/create'
      ariaLabel='Create first event button'
    >
      <svg width={0} height={0}>
        <radialGradient
          id='borderGradient'
          cx='0%'
          cy='100.01%'
          r='138.99%'
          fx='0%'
          fy='100.01%'
        >
          <stop offset='0%' stopColor='#240448' />
          <stop offset='28.81%' stopColor='#1200B5' />
          <stop offset='64.04%' stopColor='#9C0DC8' />
        </radialGradient>
      </svg>
      <Add
        sx={[
          {
            mr: 1,
          },
          ...((Array.isArray(sx) && sx.some((style) => style?.color)) ||
          (!Array.isArray(sx) && (sx as any)?.color)
            ? []
            : [
                {
                  fill: 'url(#borderGradient)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                },
              ]),
        ]}
      />
      {t('newIndex.createYourFirstEvent')}
    </GradientButton>
  )
}

export default CreateFirstEventButton
