import React from 'react'
import { Theme } from '@mui/material/styles'
import { Button, SxProps, Typography } from '@mui/material'
import Row from '../Row'
import LogoGradient from '../../svg/logo/LogoGradient'
import { useTranslation } from 'react-i18next'
import { Add, Search } from '@mui/icons-material'
import GradientButton from '../../shared-components/buttons/GradientButton'
import { indexMobileBreakpoint } from '../../types/types'

interface Props {
  sx?: SxProps<Theme>
}

const HomeHeader = ({ sx = [] }: Props) => {
  const { t } = useTranslation('common')
  return (
    <Row
      sx={[
        {
          py: 3,
          justifyContent: 'space-between',
          maxWidth: 1120,
          bgcolor: '#fff',
          px: 3,
          width: 1,
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <LogoGradient
        sx={{
          width: 21,
          height: 20,
        }}
      />

      <Row
        sx={(theme) => ({
          alignItems: 'center',
          gap: 4,
          [theme.breakpoints.down(indexMobileBreakpoint)]: {
            gap: 1,
          },
        })}
      >
        <Button
          color='inherit'
          disableRipple
          sx={(theme) => ({
            gap: 0.75,
            color: 'secondary.950',
            minWidth: 0,
            transition: 'color 0.2s ease-in-out',
            [theme.breakpoints.down(indexMobileBreakpoint)]: {
              px: 0,
            },
            '&:hover': {
              bgcolor: 'transparent',
              color: 'secondary.800',
            },
          })}
          href={'/create'}
          aria-label='Create event'
        >
          <Add
            sx={{
              width: 20,
              height: 20,
            }}
          />
          <Typography variant='button'>{t('createEvent')}</Typography>
        </Button>

        <Button
          color='inherit'
          disableRipple
          sx={{
            gap: 0.75,
            color: 'secondary.950',
            transition: 'color 0.2s ease-in-out',
            '&:hover': {
              bgcolor: 'transparent',
              color: 'secondary.800',
            },
          }}
          href={'/foryou'}
          aria-label='Hot events'
        >
          <Search
            sx={{
              width: 20,
              height: 20,
            }}
          />
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.down(indexMobileBreakpoint)]: {
                display: 'none',
              },
            })}
            variant='button'
          >
            Hot events
          </Typography>
        </Button>

        <GradientButton href={'/signIn'} small ariaLabel='Sign in'>
          {t('signIn')}
        </GradientButton>
      </Row>
    </Row>
  )
}

export default HomeHeader
