import React from 'react'
import { Box, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface Props {
  sx?: SxProps<Theme>
}

const IndexOrganiserCarousel = ({ sx = [] }: Props) => {
  const images = [
    'm',
    'crea',
    'csc',
    'oosterbar',
    'vbx',
    'rooftoptapes',
    'yuc',
    'benelux',
  ]

  // Calculate how many copies we need
  const totalSets = 3

  return (
    <Box
      sx={[
        {
          maxWidth: 1120,
          width: 1,
          overflow: 'hidden',
          position: 'relative',
          // Using mask image instead of overlay elements
          maskImage:
            'linear-gradient(to right, transparent, black 20%, black 80%, transparent 100%)',
          WebkitMaskImage:
            'linear-gradient(to right, transparent, black 20%, black 80%, transparent 100%)',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          width: 'fit-content', // Important for proper sizing
          animation: 'carouselScroll 40s linear infinite',
          gap: 6,
          '@keyframes carouselScroll': {
            '0%': { transform: 'translateX(0)' },
            '100%': { transform: `translateX(calc(-${100 / totalSets}%))` },
          },
        }}
      >
        {/* Create multiple complete sets of images */}
        {[...Array(totalSets)].map((_, setIndex) => (
          <Box
            key={`set-${setIndex}`}
            sx={{
              display: 'flex',
              flexShrink: 0,
              gap: 6,
            }}
          >
            {images.map((item, imgIndex) => (
              <Box
                key={`img-${setIndex}-${imgIndex}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: 2, // Padding on x-axis (horizontally)
                  flexShrink: 0,
                }}
              >
                <img
                  src={`/images/home/organisers/organiser_${item}.svg`}
                  alt={item}
                  style={{
                    height: '40px',
                    width: 'auto',
                    display: 'block',
                    minWidth: 60,
                  }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default IndexOrganiserCarousel