import Column from '../Column'
import Row from '../Row'
import TextLogo from '../../svg/TextLogo'
import { Box, SxProps, Typography } from '@mui/material'
import React from 'react'
import { Theme } from '@mui/material/styles'
import { indexMobileBreakpoint } from '../../types/types'
import { Trans, useTranslation } from 'react-i18next'
import CreateFirstEventButton from './CreateFirstEventButton'
import IndexOrganiserCarousel from './IndexOrganiserCarousel'
import { solarDisplay } from '@public/fonts/solarDisplay'
import { indexBodyText } from '../../types'

// const Lottie = dynamic(() => import('lottie-react'), {
//   ssr: false,
// })

// const Lottie = dynamic(() => import('lottie-react'), {
//   loading: () => <IndexHeroFrameOne />,
//   ssr: false,
// })

interface Props {
  sx?: SxProps<Theme>
}

const IndexTopSection = ({ sx = [] }: Props) => {
  const { t } = useTranslation('common')

  return (
    <Column
      sx={(theme) => ({
        width: 1,
        alignItems: 'center',
        minHeight: 'calc(115vh - 80px)',
        justifyContent: 'space-around',
        background: 'linear-gradient(to bottom, #fff 55%, #FEF9FA)',
        [theme.breakpoints.down(indexMobileBreakpoint)]: {
          height: 'auto',
        },
      })}
    >
      {/*<Box />*/}
      <Column
        sx={[
          (theme) => ({
            maxWidth: 1120,
            px: 3,
            justifyContent: 'space-around',
            [theme.breakpoints.down(indexMobileBreakpoint)]: {
              pt: 0,
              pb: 0,
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Row
          sx={(theme) => ({
            width: 1,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            [theme.breakpoints.down(indexMobileBreakpoint)]: {
              gridTemplateColumns: '1fr',
              gap: 0,
              mb: 10,
            },
          })}
        >
          {/* Left column with text content */}
          <Column
            sx={{
              gap: 4,
              alignSelf: 'center',
              minHeight: 307,
              justifyContent: 'center',
            }}
          >
            <TextLogo />
            <Column
              sx={{
                gap: 3,
                alignItems: 'flex-start',
              }}
            >
              <Typography
                variant='h1'
                sx={{
                  // fontFamily: 'SolarDisplay',
                  fontFamily: solarDisplay.style.fontFamily,
                  fontWeight: 800,
                  fontSize: '2.5rem',
                  lineHeight: '94%',
                  letterSpacing: '0.1rem',
                  textTransform: 'uppercase',
                  color: 'secondary.950',
                  background:
                    'linear-gradient(89.01deg, #290057 21.37%, #1200B5 57.59%, #9C0DC8 96.95%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                <Trans
                  t={t}
                  i18nKey='newIndex.hero'
                  components={{ break: <br /> }}
                />
              </Typography>

              <Typography
                variant='body1'
                component='h2'
                sx={[{}, indexBodyText]}
              >
                <Trans
                  t={t}
                  i18nKey='newIndex.heroCaption'
                  components={{
                    break: (
                      <Box
                        component='br'
                        sx={(theme) => ({
                          [theme.breakpoints.down(indexMobileBreakpoint)]: {
                            display: 'none',
                          },
                        })}
                      />
                    ),
                  }}
                />
              </Typography>
            </Column>

            <CreateFirstEventButton />
          </Column>

          {/* Right column with animation */}
          <Column
            sx={(theme) => ({
              alignItems: 'flex-end',
              flexGrow: 1,
              justifyContent: 'center',
              [theme.breakpoints.down(indexMobileBreakpoint)]: {
                order: -1,
                py: 4,
              },
            })}
          >
            {/* Animation container with fixed dimensions to prevent layout shifts */}
            <Box
              sx={(theme) => ({
                width: 0.9,
                // mr: -4,
                [theme.breakpoints.down(indexMobileBreakpoint)]: {
                  mr: 0,
                  width: 1,
                },
                // '& > div > svg >  g:last-of-type > g:not(:first-of-type):last-of-type > g:first-of-type:last-of-type':
                //   {
                //     display: 'none !important',
                //   },
              })}
            >
              <video
                preload='metadata'
                autoPlay
                muted
                playsInline
                loop
                style={{
                  width: '100%',
                  aspectRatio: '1.471875',
                }}
                poster='/images/home/landing-hero-2x.jpg'
              >
                {/* High-resolution desktop (2x pixel density) */}
                <source
                  src='/images/home/landing_hero_2x.mp4'
                  type='video/mp4'
                  media='(min-width: 768px) and (min-device-pixel-ratio: 2),
             (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
             (min-width: 768px) and (min-resolution: 192dpi)'
                />
                {/* Standard desktop */}
                <source
                  src='/images/home/landing_hero.mp4'
                  type='video/mp4'
                  media='(min-width: 768px)'
                />
                {/* High-resolution mobile */}
                <source
                  src='/images/home/landing_hero_mobile_2x.mp4'
                  type='video/mp4'
                  media='(max-width: 767px) and (min-device-pixel-ratio: 2),
             (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2),
             (max-width: 767px) and (min-resolution: 192dpi)'
                />
                {/* Standard mobile */}
                <source
                  src='/images/home/landing_hero_mobile.mp4'
                  type='video/mp4'
                  media='(max-width: 767px)'
                />
                <source src='/images/home/landing_hero.mp4' type='video/mp4' />
                {/*<source*/}
                {/*  src='/images/home/landing_hero.mp4'*/}
                {/*  type='video/mp4'*/}
                {/*  media={`(min-width: ${indexMobileBreakpoint}px)`}*/}
                {/*/>*/}
                {/*<source*/}
                {/*  src='/images/home/landing_hero_small.mp4'*/}
                {/*  type='video/mp4'*/}
                {/*  media={`(max-width: ${indexMobileBreakpoint - 1}px)`}*/}
                {/*/>*/}
                Your browser does not support the video tag.
              </video>
            </Box>
          </Column>
        </Row>
      </Column>

      {/* Trust section with carousel */}
      <Column
        sx={{
          gap: 4.5,
          width: 1,
          overflowX: 'hidden',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h4'
          component='h3'
          sx={[
            {
              px: 3,
              fontWeight: 700,
              color: 'secondary.950',
              textAlign: 'center',
            },
            indexBodyText,
          ]}
        >
          {t('newIndex.heroTrust')}
        </Typography>

        <IndexOrganiserCarousel
          sx={(theme) => ({
            [theme.breakpoints.down(indexMobileBreakpoint)]: {
              pb: 10,
            },
          })}
        />
      </Column>
    </Column>
  )
}

export default IndexTopSection
